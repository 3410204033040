.citation-titre {
    font-size: 1rem;
    color: #E1E2DC;
    font-weight: lighter;
    margin-right: 0px;
}

.citation-auteur {
    font-size: 1rem;
    color: #E1E2DC;
    font-weight: normal;
}

@media only screen
and (min-width: 321px) {
    .citation-titre {
        font-size: 1.2rem;
    }
    .citation-auteur {
        font-size: 1.2rem;
    }
}

@media only screen
and (min-width: 641px) {
    .citation-titre {
        font-size: 1.3rem;
    }
    .citation-auteur {
        font-size: 1.3rem;
    }
}

@media only screen
and (min-width: 800px) {
    .citation-titre {
        font-size: 1.4rem;
    }
    .citation-auteur {
        font-size: 1.4rem;
    }
}

.img-home {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 50vh;
}

.ny-quote-margin {
    background-image: url("./images/quote-marge.png");
    background-repeat: repeat-y;
}

.ny-quote {
    width:90%;
    margin-left:auto;
    margin-right:auto;
}

.ny-center {
    display: block;
    margin-left:auto;
    margin-right:auto;
}

.ny-quote-img img {
    height: 5px;
    width: auto;
}
