.image-gallery-slide {
    background-color: #1B1D21;
}

.image-gallery-slide img {
    object-fit: contain;
    max-height: calc(100vh - 160px);
    background-color: #1B1D21;
    border-radius: 5px;
    width: auto;
    display:block;
    margin-left:auto;
    margin-right:auto;
}

.fullscreen .image-gallery-slide img {
/*    object-fit: scale-down;  */
    max-height: 95vh;
    background-color: #1B1D21;
}

.image-gallery-thumbnail {
    width: 60px;
    border: 2px solid transparent;
    margin-left: 0px;
    margin-right: 0px;
}

.image-gallery-thumbnail img {
    border-radius: 4%;
}

.image-gallery-thumbnails {
    padding: 10px 0;
}
