.expo-orange {
    background-color: #ffe3c9;
}

.expo-blue {
    background-color: #e2edff;
}

.expo-red {
    background-color: #ffd7d6;
}

.expo-titre1 {
    color: #505050;
}

.expo-titre {
    font-weight: bold;
    color: #505050
}

.extlink {
    color: #6da1ed;
}

.blue {
    background-color: blue;
}
