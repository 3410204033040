.ny-menu-item {
    font-size: 1rem;
    color: #E1E2DC;
}

.fa.fa-navicon {
    color: #E1E2DC;
}

@media only screen
and (orientation: landscape) {
    .ny-menu-item {
        font-size: 1.1rem;
        color: #E1E2DC;
    }
}
